import React from "react";

import { CustomAutocomplete } from "#shared/components/ui/Autocomplete";
import { CustomAutocompleteProps } from "#shared/components/ui/Autocomplete/CustomAutocompleteNew";
import Checkbox from "#shared/components/ui/Checkbox";
import { SelectOption, SelectOptionValue } from "#shared/types/form";

interface Props<T extends SelectOptionValue>
  extends Omit<CustomAutocompleteProps, "onChange" | "value"> {
  options: SelectOption<T>[];
  value: T[];
  onChange: (value: T[]) => void;
}

export default function BuilderMultiselect<T extends SelectOptionValue>({
  options,
  placeholder,
  value,
  onChange,
  ...props
}: Props<T>) {
  const mappedValue = value.reduce<SelectOption<T>[]>((acc, item) => {
    const option = options.find((opt) => opt.value === item);
    if (option) {
      acc.push(option);
    }
    return acc;
  }, []);

  const handleChange = (val: SelectOption<T>[]) => {
    onChange(val.map((item) => item.value));
  };

  return (
    <CustomAutocomplete
      {...props}
      multiple
      options={options}
      value={mappedValue}
      ChipProps={{ color: "primary" }}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.value}>
          <Checkbox sx={{ m: 0 }} disableRipple checked={selected} />
          {option.label}
        </li>
      )}
      onChange={(_, val) => {
        if (val && Array.isArray(val)) {
          handleChange(val as SelectOption<T>[]);
        }
      }}
      placeholder={!mappedValue.length ? placeholder || "Select" : undefined}
    />
  );
}
