import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

export default function useIntersectObserver<T>({
  root = null,
  rootMargin = "0px",
  threshold = 0,
}): [Dispatch<SetStateAction<T | undefined>>, IntersectionObserverEntry | undefined] {
  const [entry, updateEntry] = useState<IntersectionObserverEntry | undefined>();
  const [nodeRef, setNodeRef] = useState<T>();
  const observer = useRef(
    typeof window !== "undefined"
      ? new IntersectionObserver(([entry]) => updateEntry(entry), {
          root,
          rootMargin,
          threshold,
        })
      : null
  );

  useEffect(() => {
    if (observer) {
      const { current: currentObserver } = observer;
      if (currentObserver) {
        currentObserver.disconnect();
        const refElement = nodeRef as HTMLElement;
        if (nodeRef) currentObserver.observe(refElement);

        return () => currentObserver.disconnect();
      }
    }
  }, [nodeRef]);

  return [setNodeRef, entry];
}

// this is a more versatile implementation than the one above, try using this one instead
interface Args {
  onEntryStateChange(entry: IntersectionObserverEntry): void;
  onObserverNotSupported?: () => void;
  checkTicker?: boolean; // re-runs the intersection observer
  useViewportObserverRoot?: boolean;
  observedItems?: HTMLElement[]; // for supporting multiple obserbable items
  rootMargin?: string;
  threshold?: number | number[];
}

export const useCustomIntersectionObserver = (args: Args) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const observedItem = useRef<HTMLElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const observerCallback: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      args.onEntryStateChange(entry);
    });
  };

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    if (!window.IntersectionObserver) {
      args.onObserverNotSupported?.();
      return;
    }

    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    const options: IntersectionObserverInit = {
      root: args.useViewportObserverRoot ? undefined : wrapper.current,
      rootMargin: args.rootMargin || "50px",
      threshold: args.threshold ?? 1,
    };

    observerRef.current = new IntersectionObserver(observerCallback, options);

    if (args.observedItems?.length) {
      args.observedItems.forEach((item) => {
        if (item) observerRef.current?.observe(item);
      });
    }

    if (observedItem.current) {
      observerRef.current.observe(observedItem.current);
    }

    return () => {
      observerRef.current?.disconnect();
      observerRef.current = null;
    };
  }, [
    args.checkTicker,
    args.useViewportObserverRoot,
    args.rootMargin,
    args.threshold,
    args.observedItems,
  ]);

  return { wrapper, observedItem };
};
