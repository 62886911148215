import React, { useRef } from "react";

import { css, styled } from "@mui/material";

import { useGetSuggestedChannels } from "#apis/channels/hooks/useGetSuggestedChannels";
import SuggestedChannelsDesktop from "#components/pages/Feed/ChannelsSidebar/SuggestedChannels/SuggestedChannelsDesktop";
import SuggestedChannelsInterests from "#components/pages/Feed/ChannelsSidebar/SuggestedChannels/SuggestedChannelsInterests";
import SuggestedChannelsMobile from "#components/pages/Feed/ChannelsSidebar/SuggestedChannels/SuggestedChannelsMobile";
import useSuggestedChannelsInterests from "#components/pages/Feed/ChannelsSidebar/SuggestedChannels/hooks/useSuggestedChannelsInterests";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";

interface Props {
  type?: "desktop" | "mobile";
}

export default function SuggestedChannels({ type = "desktop" }: Props) {
  const { isMobile, isDesktop } = useMediaQueryProvider();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { data, isFetching: isLoadingChannels } = useGetSuggestedChannels();

  const channels = data?.data || [];

  const {
    interests,
    isLoadingInterests,
    showSelectInterests,
    setShowSelectInterests,
    handleConfirmInterests,
    handleCloseInterests,
  } = useSuggestedChannelsInterests();

  const handleSelectInterests = () => {
    setShowSelectInterests(true);

    if (isMobile && wrapperRef.current) {
      window.scrollTo({ top: wrapperRef.current.offsetTop, behavior: "smooth" });
    }
  };

  const content = () => {
    if (showSelectInterests) {
      return (
        <SuggestedChannelsInterests
          interests={interests}
          onClose={handleCloseInterests}
          onConfirm={handleConfirmInterests}
        />
      );
    }

    if (isDesktop) {
      return (
        <SuggestedChannelsDesktop
          channels={channels}
          isLoadingChannels={isLoadingChannels}
          onSelectInterests={handleSelectInterests}
        />
      );
    }

    return (
      <SuggestedChannelsMobile
        channels={channels}
        isLoadingChannels={isLoadingChannels}
        onSelectInterests={handleSelectInterests}
      />
    );
  };

  if (
    isLoadingInterests ||
    (type === "desktop" && !isDesktop) ||
    (type === "mobile" && isDesktop)
  ) {
    return null;
  }

  return <Wrapper ref={wrapperRef}>{content()}</Wrapper>;
}

const Wrapper = styled("div")`
  position: relative;
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;

  .filters.private:last-child {
    margin-bottom: 0;
  }
`;
