import React, { useRef, useState, DragEvent } from "react";
import { getExtensionFromMimeType } from "./helpers";

// remove "imageWithUpload" when audio capture is merged
export type MediaTypes =
  | "all"
  | "image"
  | "video"
  | "audio"
  | "imageWithUpload"
  | "csvText";

interface Props {
  mediaType?: MediaTypes;
  acceptedMedia: string | undefined; // overrides mediaType and enables manual specification of accepted types
  disabled?: boolean;
  onChange: (files: File[]) => void;
  onError?: (error: string) => void;
}

const image = "image/png,image/jpg,image/jpeg";
const video = "video/mp4,video/quicktime,video/webm";
const audio = "audio/aac,audio/mpeg,audio/ogg,audio/flac";
const imageWithUpload = `${image},${getExtensionFromMimeType(image)};capture=camera`;
const csvText = "text/csv,text/plain";

export const acceptedTypes = {
  image: `${image},${getExtensionFromMimeType(image)}`,
  video: video,
  audio: audio,
  imageWithUpload,
  csvText: csvText,
  all: `*`,
};

export default function useDropzone({
  mediaType = "all",
  acceptedMedia,
  disabled,
  onChange: onFilesSelect,
  onError,
}: Props) {
  const [isActive, setIsActive] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const accept = acceptedMedia || acceptedTypes[mediaType];

  const clearInputValue = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const onUploadClick = () => {
    if (disabled) {
      return;
    }

    clearInputValue();
    inputRef.current?.click();
  };

  const onClick = () => {
    if (disabled) {
      return;
    }

    clearInputValue();
    setIsActive(true);
  };

  const setInactive = () => setIsActive(false);

  const checkIfAcceptedType = (file: File) => {
    if (accept === acceptedTypes.all) {
      return true;
    }

    return accept.includes(file.type);
  };

  const onUnsupportedFileType = () => {
    onError?.("errors.file_not_supported");
  };

  const onChange = (
    event: React.BaseSyntheticEvent<unknown, unknown, HTMLInputElement>,
  ) => {
    if (!event.target?.files?.length) {
      return;
    }
    const files = Array.from(event.target.files);
    if (!files.length) {
      return;
    }

    const validFiles = files.filter(checkIfAcceptedType);

    if (validFiles.length) {
      onFilesSelect(files);
    } else {
      onUnsupportedFileType();
    }
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();
    clearInputValue();

    const files = Array.from(event.dataTransfer.files);
    if (!files.length) {
      return;
    }

    const validFiles = Array.from(files).filter(checkIfAcceptedType);
    if (validFiles.length) {
      onFilesSelect(validFiles);
    } else {
      onUnsupportedFileType();
    }
  };

  const onDragOver = (e: DragEvent) => {
    e.preventDefault();
  };

  return {
    inputRef,
    isActive,
    onUploadClick,
    onClick,
    setInactive,
    onChange,
    onDrop,
    onDragOver,
    accept,
  };
}
