type Params = Partial<Record<string, string>>;

export const identifyParams = {
  isChannelDetails: (params: Params): boolean => {
    return !!params["*"]?.startsWith("channel/");
  },
  isBriefDetails: (params: Params): boolean =>
    !!(params.briefRef && params["*"]?.startsWith("feed/")),
  isPrivateFeed: (params: Params): boolean => !!params["*"]?.startsWith("feed/following"),
};

export const identifyPathname = {
  isBriefBuilder: (pathname: string) => pathname.startsWith("/brief-builder"),
  isSmartBriefBuilder: (pathname: string) => pathname.startsWith("/smart-brief-builder"),
  isBusinessCentre: (pathname: string) => pathname.startsWith("/business-centre"),
  isSurveyPreview: (pathname: string) => pathname.startsWith("/survey/preview/"),
  isBriefDetails: (pathname: string) =>
    pathname.startsWith("/feed/brief") || pathname.startsWith("/brief/"),
  isChannelDetails: (pathname: string) => pathname.startsWith("/channel/"),
  isWrappedPage: (pathname: string) => pathname.startsWith("/wrapped"),
};
