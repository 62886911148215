import React from "react";

import LoginHeader from "#components/common/LoginComponents/LoginHeader";
import UpdateProfileContainer from "#containers/UpdateProfile";
import useTranslation from "#utils/useTranslation";

export default function UpdateProfile() {
  const { t, p } = useTranslation();
  return (
    <>
      <LoginHeader
        title={t("pages.profile_update.content.title")}
        subtitle={p("pages.profile_update.content.p")}
      />
      <UpdateProfileContainer />
    </>
  );
}
