import React from "react";

import { Box, Link, styled, Typography } from "@mui/material";

import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import useTranslation from "#utils/useTranslation";
import useWlaOrganisation from "#utils/useWlaOrganisation";
import Presents from "./presents.svg?react";

interface Props {
  type: "desktop" | "mobile";
}

export default function SidebarRecap({ type }: Props) {
  const { t } = useTranslation();
  const { isWla } = useWlaOrganisation();

  const { isTabletOrMobile } = useMediaQueryProvider();

  const config = {
    year: 2024,
    href: `/wrapped/?page=welcome`,
  };

  if (isWla) {
    return null;
  }

  if (
    (type === "desktop" && isTabletOrMobile) ||
    (type === "mobile" && !isTabletOrMobile)
  ) {
    return null;
  }

  return (
    <Box my={4}>
      <Typography
        variant="label3"
        fontFamily="Avenir-heavy"
        color={colors.text.secondaryDark}
        mb={3}
      >
        {t("ui.yearly_recap.title", { year: config.year })}
      </Typography>

      <ItemWrapper href={config.href}>
        <Presents />
        <div className="title">
          <div className="text yellow">#BestOfBulbshare</div>
          <div className="text" style={{ marginTop: "2px" }}>
            Click to see your 2024 recap
          </div>
        </div>
      </ItemWrapper>
      {/* <SidebarCard
        title={t("ui.yearly_recap.title", { year: config.year })}
        subtitle={user.profile.displayName}
        description={t("ui.yearly_recap.description", { year: config.year })}
        href={config.href}
        Avatar={<Bulbshare2024 width={32} height={32} />}
        Actions={
          <Button
            variant="cta"
            size="extraSmall"
            sx={{ textTransform: "uppercase", p: 0, height: "auto", minWidth: 0 }}
          >
            {t("ui.buttons.view")}
          </Button>
        }
        sx={{ display: "block" }}
      /> */}
    </Box>
  );
}

const ItemWrapper = styled(Link)`
  display: flex;
  gap: 25px;
  background: #0b700f;
  position: relative;
  box-sizing: border-box;
  padding: 1rem;
  scroll-snap-align: start;
  border-radius: ${rounding.default};
  cursor: pointer;
  height: 106px;
  text-decoration: none;
  color: inherit;

  svg {
    height: 100%;
    width: auto;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text {
      font-size: 1.2rem;
      font-weight: 900;
      color: #f6e5dd;

      &.yellow {
        color: #fee700;
      }
    }
  }
`;
