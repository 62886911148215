// modules
import React from "react";

import { styled } from "@mui/material";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";

import DownloadModal from "#containers/DownloadModal";
import GlobalAppProvider from "#containers/Global/GlobalAppProvider";
import GodViewGlobalListener from "#containers/GodView/GodViewGlobalListener";
import HeaderContainer from "#containers/layout/Header";
import MagicButtonWrapper from "#containers/layout/MagicButton";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { identifyPathname } from "#utils/routeHelpers";
import { useIsMobileApp } from "#utils/useIsMobileApp";
// components
import { getRoutePathname, isProtectedPage } from "../../../utils";

export default function WrapperPrivateRoute() {
  const location = useLocation();
  const routePath = getRoutePathname();
  const { isTabletOrMobile } = useMediaQueryProvider();
  const { isMobileApp } = useIsMobileApp();

  const isBriefBuilder = identifyPathname.isBriefBuilder(location.pathname);
  const isSmartBriefBuilder = identifyPathname.isSmartBriefBuilder(location.pathname);
  const isBusinessCentre = identifyPathname.isBusinessCentre(location.pathname);
  const isSurveyPreview = identifyPathname.isSurveyPreview(location.pathname);
  const isBriefDetails = identifyPathname.isBriefDetails(location.pathname);
  const isChannelDetails = identifyPathname.isChannelDetails(location.pathname);
  const isWrappedPage = identifyPathname.isWrappedPage(location.pathname);

  const header = () => {
    if (isMobileApp && isChannelDetails) {
      return null;
    }

    if (isTabletOrMobile && isBusinessCentre) {
      return null;
    }

    if (
      isSmartBriefBuilder ||
      isBriefBuilder ||
      isSurveyPreview ||
      isBriefDetails ||
      isWrappedPage
    ) {
      return null;
    }

    return <HeaderContainer isProtectedPage={isProtectedPage(routePath)} />;
  };

  const content = () => {
    if (isBriefBuilder) {
      return (
        <React.Suspense>
          <Outlet />
        </React.Suspense>
      );
    }

    return (
      <Content>
        <React.Suspense>
          <Outlet />
        </React.Suspense>
      </Content>
    );
  };

  return (
    <>
      <GlobalAppProvider>
        {header()}
        {content()}
        <MagicButtonWrapper />
      </GlobalAppProvider>
      <DownloadModal />
      <GodViewGlobalListener />
    </>
  );
}

const Content = styled("div")`
  width: 100%;
  min-height: calc(100dvh - 6.25rem);
  display: flex;
  align-items: center;
  justify-content: center;
`;
