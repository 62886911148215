import React, { HTMLAttributes, ReactNode } from "react";
import { DropzoneWrapper } from "./styled";
import useDropzone, { MediaTypes } from "./useDropzone";
import { FormHelperText, SxProps, Theme } from "@mui/material";

interface Props extends Omit<HTMLAttributes<HTMLInputElement>, "onChange" | "onError"> {
  mediaType?: MediaTypes;
  acceptedMedia?: string;
  error?: string;
  helpText?: string;
  showOverlay?: boolean;
  wrapperClassName?: string;
  rootClassName?: string;
  disabled?: boolean;
  disableClick?: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
  onChange: (files: File[]) => void;
  onDragOver?: () => void;
  onDragLeave?: () => void;
  onError?: (error: string) => void;
}

export default function Dropzone({
  error = "",
  helpText,
  showOverlay,
  children,
  mediaType,
  acceptedMedia,
  wrapperClassName,
  rootClassName,
  disabled,
  disableClick,
  onError,
  onDragOver,
  onDragLeave,
  onChange: onFilesSelected,
  sx,
  ...rest
}: Props) {
  const {
    inputRef,
    onClick,
    setInactive,
    onChange,
    onDrop,
    onDragOver: dragOver,
    accept,
  } = useDropzone({
    mediaType,
    onChange: (files) => onFilesSelected(files),
    onError,
    acceptedMedia,
  });

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (disableClick) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    onClick();
  };

  return (
    <div className={rootClassName}>
      <DropzoneWrapper
        className={wrapperClassName}
        onDrop={onDrop}
        onDragOver={(e) => {
          onDragOver?.();
          dragOver(e);
        }}
        onDragLeave={onDragLeave}
        hasError={!!error?.length}
        showOverlay={showOverlay}
        disabled={disabled}
        sx={sx}
      >
        <input
          type="file"
          {...rest}
          ref={inputRef}
          onDragOver={onClick}
          onDragLeave={setInactive}
          onFocus={onClick}
          onBlur={setInactive}
          onDrop={setInactive}
          onClick={handleClick}
          onChange={onChange}
          accept={accept}
        />

        {children}
      </DropzoneWrapper>
      {error && <FormHelperText error>{error}</FormHelperText>}
      {!error && helpText && <FormHelperText>{helpText}</FormHelperText>}
    </div>
  );
}
