import React, { ReactNode } from "react";

import { Box, css, styled } from "@mui/material";
import { useLocation } from "react-router";

import TrackingCallbackImage from "#components/TrackingCallbackImage";
import DesktopSideNavigation from "#components/pages/Feed/ChannelsSidebar/DesktopChannels";
import SuggestedChannels from "#components/pages/Feed/ChannelsSidebar/SuggestedChannels";
import useGodView from "#containers/GodView/useGodView";
import SidebarRecap from "#containers/Recap/SidebarRecap";
import {
  WrapperPage,
  WrapperContent,
  WrapperPageContent,
} from "#containers/layout/styled";
import { isFeedPage } from "#utils/feed";

interface Props {
  fullWidth?: boolean;
  children: ReactNode;
}

export default function ResidentialLayout({ fullWidth, children }: Props) {
  const { pathname } = useLocation();
  const { isEnabled: isGodViewEnabled } = useGodView();

  return (
    <WrapperPage>
      <WrapperContent fullWidth={fullWidth}>
        <Box>
          <DesktopSideNavigation />
        </Box>
        <WrapperPageContent>{children}</WrapperPageContent>
        <Box>
          <RightSidebarWrapper>
            <SidebarRecap type="desktop" />
            {isFeedPage(pathname) && !isGodViewEnabled && (
              <SuggestedChannels type="desktop" />
            )}
          </RightSidebarWrapper>
        </Box>
      </WrapperContent>
      <TrackingCallbackImage />
    </WrapperPage>
  );
}

const RightSidebarWrapper = styled(Box)(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up("md")} {
      display: block;
      position: sticky;
      top: 4.75rem;
      right: 0;
      margin: 0;
    }
  `
);
