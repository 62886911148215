import React, { ReactNode } from "react";

import { Box, Link, SxProps, Theme, Typography, styled } from "@mui/material";

import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";

interface Props {
  Avatar?: ReactNode;
  title: string;
  subtitle?: string | ReactNode;
  description?: string;
  Actions?: ReactNode;
  href?: string;
  sx?: SxProps<Theme>;
}

export default function SidebarCard({
  Avatar,
  title,
  subtitle,
  description,
  Actions,
  href,
  sx,
}: Props) {
  return (
    <ItemWrapper tabIndex={0} href={href} sx={sx}>
      <ItemHeader>
        <Box display="flex" flex="1" pr={4}>
          {Avatar}
          <Box ml={Avatar ? 2 : 0}>
            <ItemName variant="body4" fontFamily="Avenir-heavy">
              {title}
            </ItemName>
            {subtitle && (
              <Typography variant="label3" color="text.secondary">
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
        {Actions && <ItemActions>{Actions}</ItemActions>}
      </ItemHeader>
      {description && <Description variant="label3">{description}</Description>}
    </ItemWrapper>
  );
}

const ItemWrapper = styled(Link)`
  position: relative;
  box-sizing: border-box;
  padding: 1rem;
  scroll-snap-align: start;
  background: ${colors.grey[50]};
  border-radius: ${rounding.default};
  cursor: pointer;
  min-height: 96px;
  text-decoration: none;
  color: inherit;
`;

const ItemHeader = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const ItemActions = styled("div")`
  display: flex;
  align-items: center;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
`;

const ItemName = styled(Typography)(
  ({ theme }) => `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 136px;

  ${theme.breakpoints.down("md")} {
		max-width: 170px;
	}
`
);

const Description = styled(Typography)`
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
`;
